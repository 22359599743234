@import "App.scss";

.checkCaptureContainer {
  grid-column: auto;
  grid-template-columns: max-content;
  justify-content: center;
  text-align-last: center;
  .paymentDetails {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  .fieldsContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}
.textContainer {
  align-self: center;
}
.checkContainer {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: center;
  align-items: flex-start;

  .checkTitle {
    text-align: center;
    margin: 1rem;
  }

  .thumbImage {
    width: 400;
  }

  .dropzoneContainer {
    background: #ffffff;
    border: 1px solid #bababa;
    border-radius: 6px;
    padding: 1rem;
    cursor: pointer;
  }

  .fileUploadText {
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  .dropzoneText {
    margin: auto;
    margin-left: 1px;
  }
}

.costToBuyerFieldsContainer {
  display: flex !important;
  gap: 16px !important;
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .checkImage {
    img {
      width: 300px;
      height: 150px;
    }
  }
  .checkCaptureContainer {
    grid-template-columns: inherit;
    min-height: 50rem;
  }
  .checkContainer {
    grid-template-columns: inherit;
  }
  .textContainer > div {
    text-align: center;
  }
  .costToBuyerFieldsContainer {
    display: flex !important;
    flex-direction: column !important;
  }
}
