@import "react-toastify/dist/ReactToastify.css";

/*colors variables*/

$primary: #7851f8;
$primary2: #4c2c96;
$secondary: #f6f3fc;
$error: #f9635e;
$success: #5abfae;
$warning: #f5b529;
$waiting: #f2b90c;
$white: #ffffff;
$subTitle1: #636363;
$body: #f8f8f8;
$grey: rgb(102, 102, 102);
$scrollBar: rgb(181 178 178);

/*box-shadow variables*/

$bladeboxShadow: -8px -2px 20px -10px rgba(0, 0, 0, 0.25);

/*background linear gradient*/
$bg-linear-gradient: linear-gradient(180deg, $primary 0%, #576997 100%);

main {
  min-height: 100vh !important;
}

body {
  margin: 0 !important;
}

.tableActionButton {
  background-color: $white !important;
  padding: 8px !important;
}

/*MUI style overrides*/

.MuiDataGrid-panel {
  left: 62% !important;
  top: -4rem !important;
  width: auto;
}

.MuiDataGrid-cell {
  white-space: initial !important;
}

.MuiDataGrid-row:hover {
  background-color: $secondary !important;
}

.MuiListItemIcon-root > .active > svg > path {
  fill: $primary;
}

.WBRIcon {
  margin-bottom: -20px;
}

.errorChip {
  color: var(--red-red-70, #ba0d0d) !important;
  border: none !important;
  background: var(--red-red-10, #faf0f0) !important;
}
.warningChip {
  color: var(--bronze-bronze-70, #8a4d02) !important;
  background: var(--bronze-bronze-10, #fcf2e6) !important;
  border: none !important;
}
.successChip {
  color: var(--green-green-70, #20693d) !important;
  border: none !important;
  background: var(--green-green-10, #e1faeb) !important;
}
.defaultChip {
  color: var(--gray-gray-70, #707a8b) !important;
  border: none !important;
  background: var(--gray-gray-10, #f5f7fa) !important;
}
.infoChip {
  color: var(--Blue-blue_70, #1d5bbf) !important;
  border: none !important;
  background: var(--Blue-blue_10, #f0f5fc) !important;
}

.validatingFundsChip {
  color: var(--Purple-purple_70, #7d3cbd) !important;
  border: none !important;
  background: var(--Purple-purple_10, #f5f0fa) !important;
}
.requestedChip {
  color: var(--Sky-sky_70, #0a6391) !important;
  border: none !important;
  background: var(--Sky-sky_10, #e8f4fa) !important;
}

.completedChip {
  color: var(--Grey-grey-70, #707a8b) !important;
  border: none !important;
  background: var(--white-white-10, #f5f7fa) !important;
}

.limitHoldChip {
  color: var(--Brown-brown_70, #8a4d02) !important;
  border: none !important;
  background: var(--bronze-bronze-10, #fcf2e6) !important;
}

.depositedChip {
  color: var(--Sky-sky_50, #376080) !important;
  border: none !important;
  background: var(--white-white-10, #edf4f7) !important;
}

.recurringChip {
  color: var(--Purple-purple_70, #7d3cbd) !important;
  border: none !important;
  background: var(--Purple-purple_10, #f5f0fa) !important;
}

.stepperIconsWBR {
  .css-6fcnes-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--colorvar);
  }

  .css-6fcnes-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--colorvar);
  }
}

.MuiDialogContent-root {
  overflow-x: hidden !important;
}

/*
  mobile responsive media queries to adjust the font sizes
*/

@media only screen and (max-width: 1000px) {
  .MuiTypography-h1 {
    font-size: 32px !important;
    line-height: 39px !important;
  }
  .MuiTypography-h2 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
  .MuiTypography-body1 {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .MuiTypography-subtitle1 {
    font-size: 12px !important;
  }
  .MuiTypography-subtitle2 {
    font-size: 12px !important;
    line-height: 24px !important;
  }
  .MuiTypography-h5 {
    font-size: 18px !important;
    line-height: 20px !important;
  }
  .MuiTypography-h6 {
    font-size: 16px !important;
  }
}
