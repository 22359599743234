@import "App.scss";

.otpCodeContainer {
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: auto !important;
  overflow: auto;

  .otpCodeContent {
    grid-gap: 2rem;
  }

  .optionTitle {
    grid-template-columns: 1rem 9rem;
    justify-content: center;
    align-items: center;
  }

  .accountTittle {
    grid-gap: 10px;
  }

  .appStoreContainer {
    grid-template-columns: auto auto;
    grid-gap: 2rem;
  }

  .multiCredentialContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
    grid-template-columns: 1fr auto 1fr;
  }

  .accountContainer {
    justify-content: center;
    grid-template-rows: 2.2rem 13rem 2rem;
    grid-gap: 16px;

    .accountBordered {
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      padding: 1rem;
    }
  }

  .qrCodeContainer {
    justify-content: center;
    grid-template-rows: auto auto auto;

    .qrCodeCenter {
      justify-content: center;
    }
  }
}

.buttonContainer {
  margin-top: -10px !important;
  flex-grow: 1;
  height: 50px;
}

.sendCodebuttonContainer {
  flex-grow: 1;
  height: 50px;
  margin-top: 10px;
}

.otpInputContainer {
  display: flex !important;
  justify-content: center !important;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  font-size: 35px;
  margin: 4px;
}

.resendTxtContainer {
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  margin-top: -10px !important;
}

.otpWording {
  width: 320px !important;
  font-style: italic !important;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.otpButton {
  border-radius: 16px !important;
}

.invalidCodeContainer {
  border-radius: 4px !important;
  margin-top: -20px !important;
  display: flex !important;
  gap: 10px !important;
  padding: 10px !important;
  background-color: #feedee !important;
}

@media screen and (max-width: 520px) {
  .otpInput {
    width: 30px !important;
    height: 30px;
    font-size: 16px;
    margin: 4px;
  }
  .resendTxtContainer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .otpWording {
    width: 100% !important;
  }
  .otpCodeContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
