@import "App.scss";

.breadcrumbs {
  display: flex !important;
  min-height: 24px;

  > div {
    margin-right: 10px;
  }
}
