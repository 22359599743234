@import "App.scss";

.hidden {
  display: none;
}

.canvasContainer {
  .endorsementBtnContainer {
    z-index: 1 !important;
    padding: 1rem;

    .applyBtnContainer {
      grid-template-columns: auto auto;
      grid-gap: 1rem;
    }
  }

  .rotateBtn {
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 0.7rem;
  }

  .canvasImgContainer {
    position: absolute;

    img {
      margin-top: 8px !important;

      max-height: 500px !important;
      max-width: 700px !important;
    }
  }

  .canvasImgContainerEdit {
    position: absolute;

    img {
      margin-top: 8px !important;
    }
  }

  .hidden {
    display: none;
  }
}

.drawerContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
