@media screen {
  .iconContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    /* width: min-content; */
  }

  .box {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 3px;
    cursor: pointer;
  }

  .iconSvg {
    display: grid;
  }

  .pointer {
    cursor: pointer;
  }
}
