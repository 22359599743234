@import "App.scss";

.bladeContainer {
  margin-top: -5px;
  display: block !important;
  margin-bottom: 2rem;

  .nameContainer {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.divider {
  margin: 8px !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.companyContainer {
  margin: 8px !important;
  display: flex !important;
  justify-content: space-between;
}

.auditList {
  display: flex;
  flex-direction: column;
  width: 99%;

  > .header,
  > .body {
    .column {
      font-weight: 600;
    }

    .content {
      font-weight: 400;
    }

    display: flex;
    width: 100%;

    > * {
      flex: 1;
      padding: 8px;
      text-align: left;
    }

    > :last-child {
      text-align: right;
    }
  }
}

.verificationCard {
  display: flex !important;
  align-items: self-start;
  gap: 12px;
  background-color: var(--sky-sky-10, #e8f4fa) !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  color: var(--sky-sky-70, #0a6391);

  p {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }
}

.infoIcon {
  margin-top: 14px !important;
}

.transForm {
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;

  .transInput {
    max-width: 125px;
  }
}

.actionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 1rem;
  max-height: 3rem;
}

.twoFieldsContainer {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.bladeContainer {
  margin-top: 1rem;
  display: block !important;
  margin-bottom: 2rem;

  .nameContainer {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .checkboxColumnContainer {
    grid-template-columns: auto auto;
  }

  .childSwitchContainer {
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  .addCompanyBtn {
    justify-content: end;
  }

  .requiresApprovalContainer {
    align-content: flex-end;
    grid-template-columns: auto;
    grid-gap: 1rem;
  }

  .initialCheckNumberContainer {
    display: flex;
  }

  .selectButton {
    color: var(--Bank-Shot-Primary, #7851f8);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.46px;
    text-transform: capitalize;
    background: none; /* Remove background color */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    padding: 0; /* Remove padding if needed */
    margin: 0; /* Remove margin if needed */
    cursor: pointer; /* Add cursor pointer for interaction */
    float: right;
  }

  .dividerContainer {
    clear: both;
    overflow: auto;
  }

  .textBlade {
    color: #000;

    /* Body2/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}

.paymentTypesContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  .paymentTypeCheck {
    flex: 50%;
  }
}

.dropzoneContainer {
  margin-top: 16px !important;
  border: 1.5px dashed #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
}

.fileUploadText {
  display: flex !important;
  justify-content: center !important;
  gap: 16px;
}

.filePreview {
  margin-top: 16px !important;
  background: $white;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  align-items: center;
  min-height: 4rem;
  display: flex !important;

  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .cancelIcon {
    align-self: flex-start !important;
    width: 20px !important;
    padding-right: 36px;
    cursor: pointer;

    .fileCancelIcon {
      height: 15px;
      width: 15px;
    }

    .button {
      min-height: 10px;
    }
  }

  .fileNameContainer {
    display: flex !important;
    margin-left: 16px;
    gap: 16px;
    align-items: center !important;
  }
}

.uploadFileButton {
  font-family: "Inter" !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: $primary;
  font-weight: bolder !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  width: 200px !important;
  height: 36px !important;
  color: white !important;
  box-shadow: "box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5)" !important;
  margin-top: 8px !important;
}

.downloadIcon {
  width: 20px !important;
  padding-right: 48px;
  cursor: pointer;

  .fileCancelIcon {
    height: 15px;
    width: 15px;
  }

  .button {
    min-height: 10px;
  }
}
