@import "App.scss";

.depositRow {
  min-width: 50vw !important;
  display: flex !important;
  gap: 32px !important;
  justify-content: space-around !important;
  align-items: center;
}

.depositRow > * {
  width: 160px !important;
  margin: 0 5px;
}
.sameAccountRow {
  margin-bottom: 24px !important;
  min-width: 50vw !important;
  display: flex !important;
  gap: 32px !important;
  justify-content: space-around !important;
  align-items: center;
}

.sameAccountRow > * {
  width: 160px !important;
  margin: 0 5px;
}

.accountDropdown {
  width: 220px !important;
}

.updateItemStepper {
  width: 100% !important;
  min-width: 552px !important;

  .stepBtnContainer {
    justify-content: end !important;
  }
}

.confirmDetailsRow {
  margin-bottom: 24px !important;
  min-width: 50vw !important;
  display: flex !important;
  gap: 32px !important;
  justify-content: space-around !important;
  align-items: start;
}

.confirmDetailsRow > * {
  width: 160px !important;
  margin: 0 5px;
}

.responseContainer {
  background-color: #f5f4fa;
  border-radius: 15px !important;
  padding-top: 16px;
  padding-bottom: 24px;
  margin-bottom: -16px;
  margin-top: -16px;
}

.boldText {
  font-weight: bolder !important;
}

.selectOptionsContainer {
  margin-top: 16px !important;
  display: flex !important;
  gap: 16px !important;
}
