@import "App.scss";

.receivablesContainer {
  .responsiveCard {
    width: 1023px;
    height: fit-content;
  }

  .sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  .cardHeaderContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .companyCodeSection {
    grid-template-columns: auto 12rem;
    align-items: center;
    grid-gap: 1rem;
  }

  .cardListContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .sectionMargin {
    margin: 1rem 0;
  }
}
.receivablesContainerSnippet {
  background: #efede9;

  .responsiveCard {
    width: 1023px;
    height: fit-content;
  }

  .sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  .cardHeaderContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .companyCodeSection {
    grid-template-columns: auto 12rem;
    align-items: center;
    grid-gap: 1rem;
  }

  .cardListContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .sectionMargin {
    margin: 1rem 0;
  }
}

.footer {
  width: 1023px !important;
}

.cardAndFooter {
  margin-top: 50px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  flex-grow: 1;
  width: auto !important;
}
// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .sidebar {
    width: 300px;
    background-color: #f8f8f8;
    color: #000000;
    padding: 20px;
    height: 100%;
    position: absolute;
    z-index: 2;

    .sidebarHeaderContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navItem {
      margin-top: 10px;
    }
  }
  .receivablesContainer {
    .responsiveCard {
      width: 100% !important;
      position: initial;
      margin-top: 0px;
    }
  }
  .receivablesContainerSnippet {
    .responsiveCard {
      width: 100% !important;
      position: initial;
      margin-top: 0px;
    }
  }
  .footer {
    width: 100% !important;
  }
  .cardAndFooter {
    width: 100% !important;
  }
}
