@import "App.scss";

.instructionsContainer {
  background-color: #f5f4fa;
  height: 76px;
  grid-template-columns: 1fr 20fr;
  margin-bottom: 30px;
  padding: 6px 16px;
  padding-top: 16px;

  .iconInfoContainer {
    align-self: flex-start;
    width: 52px;

    .iconInfo {
      width: 25px;
      height: auto;
    }
  }
}

.fileUploadContainer {
  grid-gap: 2rem;
  height: 296px;

  .dropzoneContainer {
    background: #f8f8f8;
    border: 1.5px dashed #c4c4c4;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
  }

  .fileUploadText {
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  .fileDropzone {
    justify-items: center;
    margin-top: 2rem;
  }

  //big container for files
  .filePreview {
    background: #f6f6f675;
    border-color: #c4c4c4;
    border-style: dashed;
    border-width: 1.5px;
    border-spacing: 8px;
    border-radius: 8px;
    min-height: 4rem;
    margin-bottom: 30px;

    .fileContainer {
      background-color: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      height: 56px;
      overflow: hidden;
      position: relative;
      width: 200px;
      margin: 16px;
      grid-template-columns: 20% 70% 10%;
      //container for singular item
      .fileNameContainer {
        grid-template-columns: auto auto auto;
        align-items: center;

        .fileUploadIcon {
          margin: 16px 8px;
        }

        .text {
          width: 130px;
          overflow: clip;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cancelIcon {
          align-self: flex-start;
          cursor: pointer;

          .fileCancelIcon {
            height: 15px;
            width: 15px;
          }

          .button {
            padding: 0 !important;
            min-height: 10px;
          }
        }
      }
    }
  }
}

.conditionsList {
  margin-top: 1em;
  margin-left: -1.5em !important;
  li {
    margin-bottom: 10px;
    color: #333333;
  }
}

.headerContainer {
  display: flex !important;
  justify-content: space-between;
}

.footerContainer {
  width: 100% !important;
  display: flex !important;
  justify-content: right !important;
  .submitBtn {
    margin-left: 16px !important;
  }
}
