@import "App.scss";

.mainContainer {
  height: 100%;
}
.checkContainer {
  background: $secondary;
  border: 1px solid $primary;
  box-shadow: 0px 8px 32px -16px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  height: 28rem;

  .bordered {
    border-bottom: 1px solid #d9d9d9;
  }

  .payeeName {
    display: flex;
    align-items: flex-end;
  }

  .checkHeadContainer {
    grid-template-columns: auto 1fr 15rem;
    grid-gap: 1rem;
    padding: 2rem;

    .dateContainer {
      grid-template-columns: auto 1fr;
      max-height: 3rem;
      grid-gap: 1rem;
      align-items: flex-end;
    }

    .logoContainer {
      background-color: $white;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      justify-content: center;
      align-items: center;
    }
  }

  .payNameContainer {
    grid-template-columns: 6rem 1fr 12rem;
    grid-gap: 1rem;
    padding: 2rem;

    .amountContainer {
      background: $white;
      border: 1px solid $primary;
      border-radius: 4px;
      align-items: center;
      grid-template-columns: auto 1fr;

      > div {
        padding-left: 2rem;
      }
    }
  }

  .dollarContainer {
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    padding: 2rem;
  }

  .memoContainer {
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 1rem;
    padding: 2rem;
  }
}

// Responsive styles for mobile using media screens

@media only screen and (max-width: 700px) {
  .mainContainer {
    justify-content: center;
  }
  .checkContainer {
    width: 36rem !important;
    height: 20rem;
    transform: rotate(90deg);
    margin-top: 10rem;
    margin-left: -7.5rem !important;
    margin-right: -8rem!important;
    .checkHeadContainer, .payNameContainer {
      padding: 5px;
    }
    .dollarContainer, .memoContainer {
      padding: 5px;
      display: flex;
      align-items: flex-end;
    }
  }
}
