@import "App.scss";

.billingAccountFormContainer {
  grid-template-columns: 10fr 10fr;
  grid-gap: 1rem
}

.pricingContainer {
  grid-template-columns: 10fr 10fr 6rem;
  align-items: flex-end;
  grid-gap: 1rem;
  align-items: center;
}

.removeItemButton {
  height: fit-content;
  width: fit-content;
}

.addProductButton {
  height: fit-content;
  width: fit-content;
  padding: 3px;
}

.actionContainer {
  grid-template-columns: auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
}
