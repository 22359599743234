@import "App.scss";

.navbar {
  position: relative;
  top: 0px;
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: row;
  z-index: 1;
  justify-self: center;
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: 8px;
  overflow: hidden;
  align-content: stretch;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding-top: 5px !important;
}

.leftAlignedMenu {
  text-align: left;
}

.centeredLogo {
  text-align: center;
  justify-self: center;
}

.navbarWithoutItems {
  position: relative;
  top: 0px;
  background-color: #ffffff;
  width: 100%;
  height: 62px;
  justify-content: center;
  align-self: flex-start;
  flex-direction: row;
  z-index: 1;
  justify-self: center;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  align-content: stretch;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.fadeEffect {
  opacity: 0;
  transition: opacity 0.5s;
}
.navItems {
  display: flex;
  margin-top: 10px;

  .navItem {
    margin-right: 10px;
  }
}
