@import "App.scss";

.achDetailsContainer {
  display: flex;
  flex-direction: column;
  .paymentDetails {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  .infoContainer {
    background-color: #f5f4fa;
    padding: 1rem;
    border-radius: 8px;
    height: 8rem;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 30fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;
    .iconTextContainer {
      grid-template-columns: 2rem 1fr;
      grid-gap: 1rem;
    }
  }
}

// Responsive styles for mobile and tablets using media screens

@media only screen and (max-width: 1000px) {
  .achDetailsContainer {
    .paymentDetails {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
    .infoContainer {
      height: auto;
      grid-template-rows: 3rem auto;
      align-items: flex-start;
      grid-gap: 1rem;
      margin-bottom: 2rem;
    }
  }
}
