@import "App.scss";

.checkTitle {
  text-align: center;
  margin: 1rem;
}

.dropzoneContainer {
  background: #ffffff;
  border: 1px solid #bababa;
  border-radius: 6px;
  padding: 1rem;
  cursor: pointer;
}

.fileUploadText {
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
}

.dropzoneText {
  margin: auto;
  margin-left: 1px;
}

.changeImageContainer {
  cursor: pointer;
  background-color: $white;
  grid-template-columns: auto;
  align-items: center;
}

.changeImageBtn {
  border: 1px solid #4c2c96;
  border-radius: 25px;
  font-weight: 600;
  min-height: 2rem;
  line-height: 1.75;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .checkImage {
    img {
      width: 300px;
      height: 150px;
    }
  }
}
