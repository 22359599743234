@import "App.scss";

.selectionBg {
  background-color: $secondary;
}

.emptyResultContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeaderBarContainer {
  grid-template-columns: 1fr 25rem;
  align-items: center;
  padding: 12px;

  .filterBarContainer {
    margin-left: auto;

    .filterBarButton {
      border-radius: 50% !important;
      min-width: 0px !important;
      width: 35px;
      height: 35px;

      span {
        margin: inherit !important;
      }
    }

    .rowSelectionContainer {
      grid-template-columns: 1fr 3rem 3rem 3rem;
      align-items: center;
      grid-gap: 1rem;
    }
  }

  .searchField {
    width: 15rem !important;
    margin-top: 7px;
  }
}
