@import "App.scss";

.creditCardDetailsContainer {
  display: flex;
  flex-direction: column;

  .paymentDetails {
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 1rem;
  }

  .paymentDetailsDate {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

.fieldsInLine {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

// Responsive styles for mobile and tablets using media screens

@media only screen and (max-width: 1000px) {
  .creditCardDetailsContainer {
    .paymentDetails {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
  }
  .fieldsInLine {
    grid-template-columns: inherit;
    grid-gap: 0;
  }
}
