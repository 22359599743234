@import "App.scss";

.textFieldContainer {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  .startAlignedContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .paymentTypeFeeContainer {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    align-items: center;
  }

  .cityStateZipContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  .processorContainer {
    margin-left: 2.1rem;
  }
}
.actionContainer {
  grid-template-columns: auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
}
