@import "App.scss";

.emptyResultContainer {
  width: 300px;
  height: 700px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $secondary;
  text-align: center;
  padding: 300px;
  box-sizing: border-box;
}
.datePickerContainer {
  grid-template-columns: 25% 25% auto auto auto;
  grid-gap: 2rem;
  width: 50rem;
  justify-content: left;
  margin: auto;

  .btnContainer {
    grid-gap: 1rem;
    justify-content: center;
    margin: auto;
  }
}

.centered-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


.reportTypeContainer {
  grid-template-columns: auto auto auto;
  grid-gap: 1rem;
  max-height: 20rem;

  .icon {
    height: 3rem;
    width: 3rem;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .btnContainer {
    grid-gap: 1rem;
    justify-content: center;
  }
}
