@import "App.scss";

.mainContainer {
  .cardHeaderContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stepperContainer {
    margin-top: 40px;
    margin-bottom: 17px;
  }
}

.provideDetailsText {
  font-style: italic !important;
  margin-top: 12px !important;
  margin-bottom: 8px !important;
}

.stepText {
  text-align: center !important;
  font-size: 30px !important;
  font-weight: bolder !important;
  margin-bottom: 24px !important;
  margin-top: -10px !important;
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .mainContainer {
    .stepperContainer {
      margin-top: 0;
    }
    .responsiveStepper {
      display: none;
    }
  }
  .stepText {
    margin-bottom: -8px !important;
    margin-top: 16px !important;
  }
}
