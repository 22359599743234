@import "App.scss";

.formContainer {
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.formTwoContainer {
  display: flex !important;
  gap: 1em;
}

.secondColumnForm {
  display: table-row !important;
}

.checkContainer {
  background: $secondary;
  border: 1px solid $primary;
  box-shadow: 0px 8px 32px -16px rgba(0, 0, 0, 0.24);
  border-radius: 4px;

  .bordered {
    border-bottom: 1px solid #d9d9d9;
  }

  .payeeName {
    display: flex;
    align-items: flex-end;
  }

  .checkHeadContainer {
    grid-template-columns: auto 1fr 15rem;
    grid-gap: 1rem;
    padding: 2rem;

    .dateContainer {
      grid-template-columns: auto 1fr;
      max-height: 3rem;
      grid-gap: 1rem;
      align-items: flex-end;
    }

    .checkNumberContainer {
      justify-content: center;
      max-height: 3rem;
      grid-gap: 1rem;
      display: flex;
      align-items: flex-end;
    }
    .logoContainer {
      background-color: $white;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      justify-content: center;
      align-items: center;
    }
  }

  .payNameContainer {
    grid-template-columns: 6rem 1fr 12rem;
    grid-gap: 1rem;
    padding: 2rem;

    .amountContainer {
      background: $white;
      border: 1px solid $primary;
      border-radius: 4px;
      align-items: center;
      grid-template-columns: auto 1fr;

      > div {
        padding-left: 2rem;
      }
    }
  }

  .dollarContainer {
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    padding: 2rem;
  }

  .memoContainer {
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 1rem;
    padding: 2rem;
  }
}

.radioGroup {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  flex-direction: row !important;
}

.fileUploadContainer {
  .dropzoneContainer {
    background: #f8f8f8;
    border: 1.5px dashed #e0e0e0;
    border-radius: 4px;
    height: 3.5em;
    cursor: pointer;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em !important;
  }

  .fileUploadText {
    display: flex !important;
    gap: 16px;
  }

  .filePreview {
    background: $white;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    align-items: center;
    height: 3.5em !important;
    display: flex !important;
    margin-top: 1em !important;
    .text {
      width: 90% !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .cancelIcon {
      cursor: pointer;
      display: flex !important;
      justify-content: flex-end !important;
      gap: 3em !important;
      margin-right: 1em !important;
      button {
        padding: 12px !important;
        min-height: 30px;
      }
    }

    .fileNameContainer {
      margin-left: 16px !important;
      display: flex !important;
      gap: 16px !important;
    }
  }
}
