.actionContainer {
  grid-template-columns: auto auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
  max-height: 3rem;
}

.contentContainer {
  grid-gap: 0.5rem;
}

.dataGridContainer {
  height: fit-content;
}

.pricingContainer {
  grid-template-columns: auto;
}

.usagePerMonthContainer {
  grid-template-columns: 18rem;
}
