@import "App.scss";

.actionContainer {
  grid-template-columns: auto auto auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
  max-height: 3rem;
}

.bladeContainer {
  margin-top: 1rem;
  display: block !important;
  margin-bottom: 2rem;

  .nameContainer {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .checkboxColumnContainer {
    grid-template-columns: auto auto;
  }

  .childSwitchContainer {
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  .addCompanyBtn {
    justify-content: end;
  }

  .requiresApprovalContainer {
    align-content: flex-end;
    grid-template-columns: auto;
    grid-gap: 1rem;
  }
  .initialCheckNumberContainer {
    display: flex;
  }
  .selectAllContainer {
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    align-items: baseline;
  }
  .selectButton {
    color: var(--Bank-Shot-Primary, #7851f8);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.46px;
    text-transform: capitalize;
    background: none; /* Remove background color */
    border: none; /* Remove border */
    outline: none; /* Remove outline */
    padding: 0; /* Remove padding if needed */
    margin: 0; /* Remove margin if needed */
    cursor: pointer; /* Add cursor pointer for interaction */
    float: right;
  }
  .textBlade {
    color: #000;

    /* Body2/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}
