.customerDataContainer {
  display: grid;
  grid-template-columns: 5rem auto;
  align-items: start;
}

.customerActionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 1rem;
  padding: 12px 20px;
}
