@import "App.scss";

.loginWrapperContainer {
  height: 100% !important;

  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  background-color: #f7f5ff !important;

  .demoTxtContainer {
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 6px;
  }

  .LoginContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center !important;
  }

  .bsInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.resetLoader {
  position: absolute;
}

.barcodeContainer {
  justify-content: center;
}

.multiFactorContainer {
  padding: 1rem;
  min-width: 40rem;
  width: auto !important;

  .multiFactorContent {
    width: 23rem;
    grid-gap: 2rem;
  }

  .optionTitle {
    grid-template-columns: 1rem 9rem;
    justify-content: center;
    align-items: center;
  }

  .accountTittle {
    grid-gap: 10px;
  }

  .appStoreContainer {
    grid-template-columns: auto auto;
    grid-gap: 2rem;
  }

  .multiCredentialContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
    grid-template-columns: 1fr auto 1fr;
  }

  .accountContainer {
    justify-content: center;
    grid-template-rows: 2.2rem 13rem 2rem;
    grid-gap: 16px;

    .accountBordered {
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      padding: 1rem;
    }
  }

  .qrCodeContainer {
    justify-content: center;
    grid-template-rows: auto auto auto;

    .qrCodeCenter {
      justify-content: center;
    }
  }
}

.loginContainer {
  margin-top: 4vh !important;
  margin-bottom: 4vh !important;
  min-height: 32rem;
  width: 440px !important;
  grid-gap: 1rem;
  padding: 20px;
  border-radius: 40px !important;
  box-shadow: 0px 0px 150px 0px rgba(76, 43, 149, 0.5);
  background-color: white;

  .formContainer {
    padding-left: 20px;
    padding-right: 20px;
    grid-gap: 1rem;
  }

  .contentLinkContainer {
    align-items: center;
    padding-bottom: 1rem;
  }
}

.autoWidth {
  width: auto !important;
}

.buttonContainer {
  flex-grow: 1;
  height: 50px;
}

.buttonsContainer {
  display: flex !important;
  height: 50px !important;
  gap: 16px !important;
}
.continueBtn {
  width: 10rem !important;
}

.qrContainer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  font-size: 35px;
  margin: 4px;
}

.twoFaButton {
  margin-top: 1rem !important;
}

.sloganContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: -5px !important;
  gap: 6px !important;
}

.loginButton {
  border-radius: 16px !important;
}

.sendPaymentContainer {
  width: 440px !important;
  padding: 20px;
  border-radius: 40px !important;
  box-shadow: 0px 0px 50px 0px rgba(76, 43, 149, 0.5);
  background-color: white;
  margin-bottom: 60px !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.sendPaymentButton {
  border-radius: 16px !important;
  height: 50px !important;
  width: 283px !important;
}

.sloganText {
  font-style: italic !important;
}
