@import "App.scss";

.stepperContainer {
  .containerPadding {
    padding: 1rem 0rem 1rem 0rem;
  }
  .stepBtnContainer {
    display: grid !important;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    justify-content: center;
    padding: 1rem 0rem 1rem 0rem;
  }
}
.iconClass {
  z-index: 1 !important;
}
