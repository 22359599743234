@import "App.scss";

.mainContainer {
  .cardHeaderContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .companyCodeSection {
    grid-template-columns: auto;
    align-items: center;
    grid-gap: 1rem;
  }
  .cardListContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  .sectionMargin {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .securityContentContainer {
    margin-top: 70px;
    background-color: #f5f4fa;
    grid-template-columns: 2rem 1fr;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 8px;
  }

  .stepperContainer {
    margin-top: 40px;
    margin-bottom: 17px;
  }

  .stepBtnContainer {
    display: grid !important;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    justify-content: center;
    padding: 1rem 0rem 1rem 0rem;
  }
}

.enterCodeTextSubtittle {
  font-style: italic !important;
  margin-top: 12px !important;
  margin-bottom: 8px !important;
}

.stepText {
  text-align: center !important;
  font-size: 30px !important;
  font-weight: bolder !important;
  margin-bottom: 24px !important;
  margin-top: -10px !important;
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .mainContainer {
    .cardListContainer {
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr 1fr;
      margin-top: 1rem;
      margin-bottom: auto;
    }
    .companyCodeSection {
      grid-template-columns: inherit;
    }

    .stepperContainer {
      margin-top: 0;
    }

    .responsiveStepper {
      display: none;
    }
  }
  .stepText {
    margin-bottom: -8px !important;
    margin-top: 16px !important;
  }
}
