@import "App.scss";

:global {
  .searchable-select__value-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .searchable-select__control {
    border-radius: 4px !important;
  }

  .searchable-select__control:hover {
    border: 1px solid $primary !important;
    box-shadow: 0 0 0 0 $primary;
  }

  .searchable-select__control--is-focused,
  .searchable-select__control--is-focused:hover {
    border: 1.5px solid $primary !important;
    box-shadow: 0 0 0 0 $primary !important;
  }

  .searchable-select__indicator-separator {
    display: none;
  }

  .searchable-select__option {
    height: 20;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  .searchable-select__option--is-focused {
    background-color: $secondary !important;
  }

  .searchable-select__placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.select {
  margin-top: 15px;
  width: 100%;
}

.disabled {
  div {
    border-color: $primary !important;
  }
}

.error {
  .materialLabel {
    color: $error !important;
  }

  :global {
    .searchable-select__control {
      border-color: $error;
    }

    .searchable-select__control:hover {
      border: 1px solid $error !important;
      box-shadow: 0 0 0 0 $error;
    }

    .searchable-select__control--is-focused,
    .searchable-select__control--is-focused:hover {
      border: 1.5px solid $error !important;
      box-shadow: 0 0 0 0 $error !important;
    }

    .searchable-select__placeholder {
      color: $error !important;
    }
  }
}

.multiSelectContainer {
  position: relative;
  margin: 1rem 0;

  .materialLabel {
    position: absolute !important;
    top: -7px !important;
    background: $white;
    z-index: 1;
    left: 7px !important;
    padding: 0 5px 0 5px !important;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.00938em;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
}
