@import "App.scss";

.sameAccountRow {
  margin-bottom: 12px !important;
  width: 35vw !important;
  max-width: 650px !important;
  display: flex !important;
  gap: 32px !important;
  justify-content: space-between !important;
  align-items: center;
}

.sameAccountRow > * {
  margin: 0 5px;
}

.updateItemStepper {
  //width: 100% !important;
  //min-width: 552px !important;

  .stepBtnContainer {
    justify-content: end !important;
  }
}

.responseContainer {
  background-color: #f5f4fa;
  border-radius: 15px !important;
  padding-top: 16px;
  padding-bottom: 24px;
  margin-bottom: -16px;
  margin-top: -16px;
}

.boldText {
  font-weight: bolder !important;
}

.selectOptionsContainer {
  margin-top: 16px !important;
  display: flex !important;
  gap: 16px !important;
}
