@import "App.scss";

.hidden {
  display: none;
}

.canvasContainer {
  .cropBtnContainer {
    z-index: 2 !important;
    padding: 1rem !important;
    display: flex !important;
  }

  .canvasImgContainer {
    position: absolute;

    img {
      margin-top: 24px !important;

      max-height: 500px !important;
      max-width: 700px !important;
    }
  }

  .canvasImgContainerEdit {
    position: absolute;

    img {
      margin-top: 24px !important;
    }
  }

  .hidden {
    display: none;
  }
}

.cropBtnsContainer {
  z-index: 2 !important;

  padding: 1rem !important;
  gap: 1rem;
  display: flex !important;
}

.cropConfirmTextContainer {
  padding: 1rem !important;
  width: 500px !important;
  display: flex !important;
}

.frontBackBtnsContainer {
  z-index: 2 !important;
  display: flex !important;
  gap: 1rem !important;
  justify-content: center !important;
}

.drawerContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rotateIcon {
  margin: auto !important;
  margin-top: 4px !important;
  width: 50px !important;
}
