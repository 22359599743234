@import "App.scss";

.settingsCardContainer {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  .redirectionBtn {
    width: auto;
    margin: auto;
    margin-top: 10px;
  }

  .cardContentContainer {
    grid-gap: 1rem;
  }
}
