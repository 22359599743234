@import "App.scss";

.actionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 7px;
  max-height: 3rem;
}

.dataGridContainer {
  height: fit-content;
}
