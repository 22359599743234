@import "App.scss";

.dataGridContainer {
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 5px 10px;

  .chipContainer {
    grid-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
}
