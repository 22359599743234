@import "App.scss";

.textFieldContainer {
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
}

.actionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 1rem;
}
