@import "App.scss";

.formContainer {
  height: 100%;
  grid-template-columns: 1fr 1fr;
}

.tabPanelContainer {
  min-height: 24rem;

  .underConstructionTypo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .btnContainer {
    padding-top: 1rem;
    justify-content: end;
  }
}
.multiFactorContainer {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 1rem;
  min-width: 40rem;
  width: auto !important;

  .multiFactorContent {
    width: 23rem;
    grid-gap: 2rem;
  }

  .optionTitle {
    grid-template-columns: 1rem 9rem;
    justify-content: center;
    align-items: center;
  }

  .accountTittle {
    grid-gap: 10px;
  }

  .appStoreContainer {
    grid-template-columns: auto auto;
    grid-gap: 2rem;
  }

  .multiCredentialContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
    grid-template-columns: 1fr auto 1fr;
  }

  .accountContainer {
    justify-content: center;
    grid-template-rows: 2.2rem 13rem 2rem;
    grid-gap: 16px;

    .accountBordered {
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      padding: 1rem;
    }
  }

  .qrCodeContainer {
    justify-content: center;
    grid-template-rows: auto auto auto;

    .qrCodeCenter {
      justify-content: center;
    }
  }
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  font-size: 35px;
  margin: 4px;
}
