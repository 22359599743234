@import "App.scss";

.dialogContainer {
  .closeIcon {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }
  .rotateIcon {
    position: fixed;
    top: 94%;
    right: 50%;
  }
  .iconLeftArrow {
    position: fixed;
    top: 50%;
    left: 1rem;
  }

  .iconRightArrow {
    position: fixed;
    top: 50%;
    right: 1rem;
  }
}

.dialogContainerVertical {
  height: 45rem;
  .closeIcon {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }
  .rotateIcon {
    position: fixed;
    top: 94%;
    right: 50%;
  }
  .iconLeftArrow {
    position: fixed;
    top: 50%;
    left: 1rem;
  }

  .iconRightArrow {
    position: fixed;
    top: 50%;
    right: 1rem;
  }
}

.imageVertical {
  height: 45rem;
}

.contentContainer {
  max-height: 40rem;
  max-width: 48rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .detailContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem;

    > div {
      grid-template-columns: auto auto;
    }
  }
}

.contentContainerVertical {
  max-height: 40rem;
  max-width: 48rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .detailContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem;

    > div {
      grid-template-columns: auto auto;
    }
  }
}
