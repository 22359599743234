@import "App.scss";

.inputTextField {
  width: 100%;
  box-sizing: content-box;
  border-radius: 4px;
  font-size: 1rem;
  border: 1px solid darkgrey;
  height: 1.4375em;
  padding: 16.5px 14px;
  background: none;
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .inputTextField {
    width: 92%;
  }
}
