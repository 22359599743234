@import "App.scss";

.menuList {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.stickyMenuList {
  position: sticky !important;
  top: 90%;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.leftIconButton {
  position: fixed !important;
  z-index: 1201;
  left: 240px;
  padding: 3px !important;
  background: $white !important;
  top: 60px;
  box-shadow: 0px 1px 3px 0px;
}

.rightIconButton {
  left: 51px;
}
