@import "App.scss";

.sendPaymentContainer {
  height: fit-content !important;

  .contentContainer {
    padding: 1rem;
    border-radius: 8px;
    height: 25rem;

    .partyIcon {
      margin-top: 2rem;
    }

    .successWhitelabel {
      margin-top: 2rem;
      text-align: center !important;
    }
  }

  .paymentInfo {
    width: 32rem;
    margin: auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    span {
      font-size: 24px;
    }
  }

  .infoText {
    text-align: center;
    justify-content: center;
    font-style: italic !important;
  }
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .sendPaymentContainer {
    .paymentInfo {
      width: auto;
    }
  }
}
