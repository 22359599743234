@import "App.scss";

.mainContainer {
  .paymentContainer {
    min-height: 30rem;
  }

  .securityContentContainer {
    background-color: #f5f4fa;
    grid-template-columns: 2rem 1fr;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 8px;
    height: auto;
  }
  .textContainer {
    align-self: center;
    text-align: center;
  }
  .contentContainer {
    background-color: #f5f4fa;
    padding: 1rem;
    border-radius: 8px;
    height: 15rem;
    justify-content: center;

    .typoContainer {
      width: 60rem;
      text-align: center;

      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .radioButtonContainer {
    grid-template-columns: 1fr 4fr;
  }
  .disclaimerContainer {
    margin-top: 30px;
  }
  .disclaimerContainerFee {
    margin-top: 10px;
    grid-template-columns: 1fr 1fr;
  }
  .disclaimerContainerAmount {
    text-align: right;
  }
  .disclaimerContainerTotalAmount {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }
  .disclaimerLink {
    text-decoration: underline;
    font-weight: bold;
  }
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .mainContainer {
    .contentContainer {
      .typoContainer {
        width: auto;
      }
    }

    .radioButtonContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
}
