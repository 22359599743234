@import "App.scss";

.footerContainer {
  padding-bottom: 20px;
  margin-top: 40px;
  .footerIcons {
    margin-top: 32px;
    width: 70%;
    padding: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}

.icon_rs_black {
  display: flex;
  justify-content: center;
}

.icon_bank_black {
  margin-bottom: 15px;
  margin-top: 5px;
}
.icon_shield_black {
  margin-bottom: 15px;
  margin-top: 5px;
}
.icon_2_1M {
  margin-bottom: -10px;
  margin-top: -10px;
}

@media only screen and (max-width: 1000px) {
  .footerIcons {
    width: 100% !important;
  }
}
