@import "App.scss";

.bannerContainer {
  background: linear-gradient(180deg, #7851f8 0%, #576997 100%);
  box-shadow: 8px 0px 32px -8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  top: 3rem;
  left: -1rem;
  right: -1rem;
  width: auto !important;
  min-height: 22rem;

  .logoContainer {
    position: absolute;
    width: auto;
    top: -45px;
    left: -3rem;
  }

  .helpTitleContainer {
    grid-template-rows: 4rem 12rem;
    margin: auto;
  }

  .helpIcon {
    width: 100%;
  }

  .helpIcon > svg > path {
    fill: $white;
  }
}

.helpCardContainer {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  position: absolute;
  top: 19rem;
  padding-bottom: 2rem;

  .cardBtn {
    width: auto;
    margin: auto;
  }
}
