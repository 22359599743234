@import "App.scss";

.paymentCategoryContainer {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  .icon {
    height: 3rem;
    width: 3rem;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .chipContainer {
    grid-template-columns: auto auto;
    grid-column-gap: 1rem;
    justify-content: center;

    .chip {
      height: "22px";
    }
  }

  .btnContainer {
    justify-content: center;

    .viewBtn {
      width: 10rem;
    }
  }
}
