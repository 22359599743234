@import "App.scss";

.paymentsContainer {
  .tabContainer {
    width: 42rem;
    margin-right: auto;
    margin-left: initial;
  }

  .bladeBodyContainer {
    .bladeTabContainer {
      display: block;
    }
  }

  .bladeFooterContainer {
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 0.5rem;
    justify-content: end;
  }

  .bladeContentDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .twoFieldsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

    .searchMyAddress {
      width: 38.6rem;
      height: 4rem;
      margin-left: 0;
    }
  }

  .singleButtonBladeFooterContainer {
    grid-template-columns: auto;
    justify-content: end;
  }
}

.contentContainer {
  max-height: 40rem;
  max-width: 48rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .detailContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem;

    > div {
      grid-template-columns: auto auto;
    }
  }
}
