@import "App.scss";

.transactionDetailContainer {
  .singleInputContainer {
    width: 25rem;
    margin-left: 0;
  }
  .buyersContainer {
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1rem;
  }

  .agentsContainer {
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1rem;
  }
  .disclaimerLink {
    text-decoration: underline;
    font-weight: bold;
  }
  .transactionInfoContainer {
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1rem;
  }
  .paymentDescriptionContainer {
    grid-template-columns: 1fr;
  }
  .transactionInfoContainer4Columns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .transactionDetailContainer {
    .singleInputContainer {
      width: 100%;
    }
    .buyersContainer {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
    .agentsContainer {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
    .transactionInfoContainer {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
    .paymentDescriptionContainer {
      width: 100%;
    }
    .transactionInfoContainer4Columns {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
  }
}
