@import "App.scss";

.stepsContainer {
  padding-top: 50px;

  .howWorksIcons {
    padding: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .iconContainer {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: #f1eeed;
  }

  .howWorksIconsMobile {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;

    .stepsContainer {
      margin-top: -30px;
      display: flex !important;
      align-items: center !important;

      .iconContainer {
        width: 70px;
        height: 55px;
        border-radius: 50%;
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: #f1eeed;
      }
    }
  }
}
