@import "App.scss";

.actionContainer {
  grid-template-columns: auto auto auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
  max-height: 3rem;
}

.bladeContainer {
  margin-top: 1rem;
  display: block !important;
  margin-bottom: 2rem;

  .nameContainer {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .checkboxColumnContainer {
    grid-template-columns: auto auto;
  }

  .childSwitchContainer {
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  .addCompanyBtn {
    justify-content: end;
  }
}
