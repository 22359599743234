@import "App.scss";

.updateItemStepper {
  width: 100% !important;
  min-width: 552px !important;

  .stepBtnContainer {
    justify-content: end !important;
  }
}
.boldText {
  color: #7851f8 !important;
  font-weight: bolder !important;
}

.responseContainer {
  background-color: #f5f4fa;
  border-radius: 15px !important;
  padding-top: 16px;
  padding-bottom: 24px;
  margin-bottom: -16px;
  margin-top: -16px;
}
