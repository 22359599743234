@import "App.scss";

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rowContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
}
