@import "../../App";

.propertyAddressContainer {
  .searchMyAddressContainer {
    width: 28.5rem;
    height: 5rem;
    margin-left: 0;
  }
  .propertyContainer {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    .stateZipContainer {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }

  .rowWithoutGapPropertyContainer {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    .stateZipContainer {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
  }

  .stateSelect {
    background-color: transparent;
  }
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .propertyAddressContainer {
    .propertyContainer {
      grid-template-columns: inherit;
      grid-gap: 0;
    }
    .searchMyAddressContainer {
      width: 100%;
      height: 4rem;
    }
    .stateSelect {
      background-color: transparent;
    }
  }
}
