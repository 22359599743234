@import "App.scss";

.userInfoContainer {
  .enterNameContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1rem;
  }

  .inputTextField {
    width: 97%;
    box-sizing: content-box;
    border-radius: 4px;
    font-size: 1rem;
    border: 1px solid darkgrey;
    height: 1.4375em;
    padding: 16.5px 14px;
    background: none;
  }
}

// Responsive styles for mobile and tablet using media screens

@media only screen and (max-width: 1000px) {
  .userInfoContainer {
    .enterNameContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
      grid-gap: 0rem;
    }

    .inputTextField {
      width: 92%;
    }
  }
}
