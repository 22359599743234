@import "App.scss";

.bladeWrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 80%;
  min-width: 20rem;
  max-width: 43rem;
  min-height: 100vh;
  box-shadow: $bladeboxShadow;

  .bladeContainer {
    height: 100%;
    grid-template-rows: 4.5rem auto 2.5rem;
  }

  .bladeHeaderContainer {
    align-items: flex-start;
    grid-template-columns: 1fr 2rem;
    grid-gap: 1rem;
    padding: 16px;

    .titleContainer {
      grid-template-columns: auto 4rem;
      justify-content: flex-start;
      grid-gap: 1rem;
      align-items: center;
    }
  }

  .bladeFooterContainer {
    align-items: flex-end;
    padding: 16px;
  }

  .bladeContentContainer {
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $scrollBar;
      border-radius: 10px;
    }
  }
}
