@import "App.scss";

.paymentsContainer {
  .tabContainer {
    width: 42rem;
    margin-right: auto;
    margin-left: initial;
  }

  .bladeBodyContainer {
    .bladeTabContainer {
      display: block;
    }
  }

  .bladeFooterContainer {
    display: flex !important;
    gap: 0.5rem !important;
    justify-content: end !important;
  }

  .bladeFooterContainer button {
    flex-shrink: 0;
    width: auto;
  }

  .bladeContentDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .twoFieldsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
    .searchMyAddress {
      width: 38.6rem;
      height: 4rem;
      margin-left: 0;
    }
  }
  .singleButtonBladeFooterContainer {
    grid-template-columns: auto;
    justify-content: end;
  }
}

.contentContainer {
  max-height: 40rem;
  max-width: 48rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .detailContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem;

    > div {
      grid-template-columns: auto auto;
    }
  }
}

.dropzoneContainer {
  background: #f8f8f8;
  border: 1.5px dashed #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
}

.fileUploadText {
  display: flex !important;
  justify-content: center !important;
  gap: 16px;
}

.filePreview {
  background: $white;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  align-items: center;
  min-height: 4rem;
  display: flex !important;
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .cancelIcon {
    align-self: flex-start !important;
    width: 20px !important;
    padding-right: 36px;
    cursor: pointer;

    .fileCancelIcon {
      height: 15px;
      width: 15px;
    }

    .button {
      min-height: 10px;
    }
  }

  .downloadIcon {
    width: 20px !important;
    padding-right: 48px;
    cursor: pointer;

    .fileCancelIcon {
      height: 15px;
      width: 15px;
    }

    .button {
      min-height: 10px;
    }
  }

  .fileNameContainer {
    display: flex !important;
    margin-left: 16px;
    gap: 16px;
  }
}

.selectDueDateContainer {
  display: flex !important;
  gap: 16px !important;
}
.selectRecurringContainer {
  display: flex !important;
  gap: 16px !important;
}
.datePickerContainer {
  display: flex !important;
  margin-left: 0px !important;
  min-width: 350px !important;
}

.feeContainer {
  display: flex !important;
  gap: 8px !important;
}
.feeOptions {
  width: 150px !important;
}

.endOptionsContainer {
  display: flex !important;
  gap: 24px !important;
  margin-bottom: -0px !important;
}
.endOptions {
  height: 210px !important;
  display: flex;
  flex-direction: column;
}

.endOptions > * {
  flex: 1 !important;
  display: flex !important;
}

.endOptionFields {
  margin-top: 60px !important;
  display: flex !important;
  flex-direction: column !important;
  height: 150px !important;
}

.ocurrencyField {
  margin-top: 8px !important;
}

.repeatEveryField {
  width: 90px !important;
}

.repeatEveryOptions {
  width: 150px !important;
}
