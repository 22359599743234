@import "App.scss";

.wireDetailsContainer {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background-color: white !important;
  border-radius: 8px !important;
  position: relative !important;
  height: 704px !important;
  width: 100% !important;
  overflow: auto;
}

.pageStyle canvas {
  margin: auto !important;
  width: 95% !important;
  height: 100% !important;
}

.securityContentContainer {
  background-color: #f5f4fa;
  grid-template-columns: 2rem 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 8px;
  height: auto;
}

.downloadContainer {
  margin-top: 24px !important;
  width: 150px !important;
}

.amountContainer {
  margin-top: 16px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: -20px;
}
.totalContainer {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8px;
}

.dropzoneContainer {
  margin-top: 16px !important;
  border: 1.5px dashed #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
}

.fileUploadText {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 16px;
  width: 332px !important;
}

.filePreview {
  margin-top: 16px !important;
  background: $white;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  align-items: center;
  min-height: 4rem;
  display: flex !important;

  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .cancelIcon {
    align-self: flex-start !important;
    width: 20px !important;
    padding-right: 36px;
    cursor: pointer;

    .fileCancelIcon {
      height: 15px;
      width: 15px;
    }

    .button {
      min-height: 10px;
    }
  }

  .fileNameContainer {
    display: flex !important;
    margin-left: 16px;
    gap: 16px;
    align-items: center !important;
  }
}

.imgPreviewContainer {
  div {
    display: flex !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 630px) {
  .wireDetailsContainer {
    height: 520px !important;
  }
}

@media screen and (max-width: 449px) {
  .wireDetailsContainer {
    height: 408px !important;
  }
}
