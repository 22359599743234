@import "App.scss";

.actionContainer {
  grid-template-columns: auto auto auto auto auto;
  justify-content: end;
  grid-gap: 1rem;
  max-height: 3rem;
  margin-right: -3em !important;
}

.bladeContainer {
  margin-top: -5px;
  display: block !important;
  margin-bottom: 2rem;

  .nameContainer {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.divider {
  margin: 8px !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.companyContainer {
  margin: 8px !important;
  display: flex !important;
  justify-content: space-between;
}

.auditList {
  display: flex;
  flex-direction: column;
  width: 99%;

  > .header,
  > .body {
    .column {
      font-weight: 600;
    }

    .content {
      font-weight: 400;
    }

    display: flex;
    width: 100%;

    > * {
      flex: 1;
      padding: 8px;
      text-align: left;
    }

    > :last-child {
      text-align: right;
    }
  }
}

.verificationCard {
  display: flex !important;
  align-items: self-start;
  gap: 12px;
  background-color: var(--sky-sky-10, #e8f4fa) !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  color: var(--sky-sky-70, #0a6391);

  p {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }
}

.infoIcon {
  margin-top: 14px !important;
}

.transForm {
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;
  .transInput {
    max-width: 125px;
  }
}

.downloadIcon {
  width: 20px !important;
  padding-right: 60px;
  cursor: pointer;

  .fileCancelIcon {
    height: 15px;
    width: 15px;
  }

  .button {
    min-height: 10px;
  }
}

.filePreview {
  margin-top: 16px !important;
  background: $white;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  align-items: center;
  min-height: 4rem;
  display: flex !important;

  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .fileNameContainer {
    display: flex !important;
    margin-left: 16px;
    gap: 16px;
    align-items: center !important;
  }
}
